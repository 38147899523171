import React from 'react';
import {
	AUTH_PREFIX_PATH,
	APP_PREFIX_PATH,
	MOBILE_PREFIX_PATH,
	MOBILE_AUTH_PREFIX_PATH,
} from 'configs/AppConfig';

export const publicRoutes = [
	{
		key: 'login',
		path: `${AUTH_PREFIX_PATH}/login`,
		component: React.lazy(() =>
			import('views/auth-views/authentication/login')
		),
	},
	// {
	// 	key: 'register',
	// 	path: `${AUTH_PREFIX_PATH}/register`,
	// 	component: React.lazy(() =>
	// 		import('views/auth-views/authentication/register')
	// 	),
	// },
	{
		key: 'forgot-password',
		path: `${AUTH_PREFIX_PATH}/forgot-password`,
		component: React.lazy(() =>
			import('views/auth-views/authentication/forgot-password')
		),
	},
	{
		key: 'reset-password',
		path: `${AUTH_PREFIX_PATH}/reset-password`,
		component: React.lazy(() =>
			import('views/auth-views/authentication/reset-password')
		),
	},
];

export const mobilePublicRoutes = [
	{
		key: 'loginMobile',
		path: `${MOBILE_AUTH_PREFIX_PATH}/login`,
		component: React.lazy(() =>
			import('views/auth-views/authentication/login/mobile')
		),
	},
	{
		key: 'loginMobileUserList',
		path: `${MOBILE_AUTH_PREFIX_PATH}/login/:role`,
		component: React.lazy(() =>
			import('views/auth-views/authentication/login/mobile/UserList')
		),
	},
];

export const mobileProtectedRoutes = [
	{
		key: 'moliendaMobile',
		path: `${MOBILE_PREFIX_PATH}/molienda`,
		component: React.lazy(() => import('views/mobile-views/molienda')),
	},
	{
		key: 'moliendaMobileProceso',
		path: `${MOBILE_PREFIX_PATH}/molienda/:procesoId`,
		component: React.lazy(() =>
			import('views/mobile-views/molienda/MoliendaForm')
		),
	},
	{
		key: 'tecnicoMobile',
		path: `${MOBILE_PREFIX_PATH}/tecnico`,
		component: React.lazy(() => import('views/mobile-views/tecnico')),
	},
	{
		key: 'tecnicoMobileAnalito',
		path: `${MOBILE_PREFIX_PATH}/tecnico/:metodoId`,
		component: React.lazy(() =>
			import('views/mobile-views/tecnico/MuestrasAnalito')
		),
	},
	{
		key: 'tecnicosStatusMobile',
		path: `${MOBILE_PREFIX_PATH}/tecnicos-status`,
		component: React.lazy(() => import('views/mobile-views/tecnicos-status')),
	},
];

export const protectedRoutes = [
	{
		key: 'proceso.id',
		path: `${APP_PREFIX_PATH}/fichas/proceso/:procesoId`,
		component: React.lazy(() =>
			import('views/app-views/fichas/proceso/ProcesoLayout.js')
		),
	},
	{
		key: 'muestra.id',
		path: `${APP_PREFIX_PATH}/fichas/muestra/:muestraId`,
		component: React.lazy(() =>
			import('views/app-views/fichas/muestra/MuestraLayout.js')
		),
	},
	{
		key: 'recepcion',
		path: `${APP_PREFIX_PATH}/modulos/recepcion`,
		component: React.lazy(() => import('views/app-views/modulos/recepcion')),
	},
	{
		key: 'recepcion.lista',
		path: `${APP_PREFIX_PATH}/modulos/recepcion/lista`,
		component: React.lazy(() =>
			import('views/app-views/modulos/recepcion/lista')
		),
	},
	{
		key: 'recepcion.nuevo',
		path: `${APP_PREFIX_PATH}/modulos/recepcion/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/modulos/recepcion/nuevo')
		),
	},
	{
		key: 'molienda',
		path: `${APP_PREFIX_PATH}/modulos/molienda`,
		component: React.lazy(() => import('views/app-views/modulos/molienda')),
	},
	{
		key: 'coordinacion',
		path: `${APP_PREFIX_PATH}/modulos/coordinacion`,
		component: React.lazy(() =>
			import('views/app-views/modulos/coordinacion')
		),
	},
	{
		key: 'coordinacion.asignacion',
		path: `${APP_PREFIX_PATH}/modulos/coordinacion/asignacion`,
		component: React.lazy(() =>
			import('views/app-views/modulos/coordinacion/asignacion')
		),
	},
	{
		key: 'coordinacion.conclusiones',
		path: `${APP_PREFIX_PATH}/modulos/coordinacion/conclusiones`,
		component: React.lazy(() =>
			import('views/app-views/modulos/coordinacion/conclusiones')
		),
	},
	{
		key: 'extraccion',
		path: `${APP_PREFIX_PATH}/modulos/extraccion`,
		component: React.lazy(() => import('views/app-views/modulos/extraccion')),
	},
	{
		key: 'analisis',
		path: `${APP_PREFIX_PATH}/modulos/analisis`,
		component: React.lazy(() => import('views/app-views/modulos/analisis')),
	},
	{
		key: 'analisis.asignacion',
		path: `${APP_PREFIX_PATH}/modulos/analisis/asignacion`,
		component: React.lazy(() =>
			import('views/app-views/modulos/analisis/asignacion')
		),
	},
	{
		key: 'analisis.lista',
		path: `${APP_PREFIX_PATH}/modulos/analisis/lista`,
		component: React.lazy(() =>
			import('views/app-views/modulos/analisis/lista')
		),
	},
	{
		key: 'resultado',
		path: `${APP_PREFIX_PATH}/modulos/resultado`,
		component: React.lazy(() => import('views/app-views/modulos/resultado')),
	},
	{
		key: 'usuarios',
		path: `${APP_PREFIX_PATH}/administracion/usuarios`,
		component: React.lazy(() =>
			import('views/app-views/administracion/usuarios/lista')
		),
	},
	{
		key: 'usuario.lista',
		path: `${APP_PREFIX_PATH}/administracion/usuarios/lista`,
		component: React.lazy(() =>
			import('views/app-views/administracion/usuarios/lista')
		),
	},
	{
		key: 'usuario.nuevo',
		path: `${APP_PREFIX_PATH}/administracion/usuarios/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/administracion/usuarios/nuevo')
		),
	},
	{
		key: 'establecimiento',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/establecimiento`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/establecimiento')
		),
	},
	{
		key: 'establecimiento.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/establecimiento/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/establecimiento/lista')
		),
	},
	{
		key: 'establecimiento.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/establecimiento/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/establecimiento/nuevo')
		),
	},
	{
		key: 'tipo-analisis',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-analisis`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/tipo-analisis')
		),
	},
	{
		key: 'tipo-analisis.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-analisis/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/tipo-analisis/lista')
		),
	},
	{
		key: 'tipo-analisis.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-analisis/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/tipo-analisis/nuevo')
		),
	},
	// {
	// 	key: 'especie',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/especie`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/especie')
	// 	),
	// },
	// {
	// 	key: 'especie.lista',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/especie/lista`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/especie/lista')
	// 	),
	// },
	// {
	// 	key: 'especie.nuevo',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/especie/nuevo`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/especie/nuevo')
	// 	),
	// },
	// {
	// 	key: 'grupo-especie',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/grupo-especie`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/grupo-especie')
	// 	),
	// },
	// {
	// 	key: 'grupo-especie.lista',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/grupo-especie/lista`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/grupo-especie/lista')
	// 	),
	// },
	// {
	// 	key: 'grupo-especie.nuevo',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/grupo-especie/nuevo`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/grupo-especie/nuevo')
	// 	),
	// },
	{
		key: 'etapa-cultivo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/etapa-cultivo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/etapa-cultivo')
		),
	},
	{
		key: 'etapa-cultivo.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/etapa-cultivo/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/etapa-cultivo/lista')
		),
	},
	{
		key: 'etapa-cultivo.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/etapa-cultivo/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/etapa-cultivo/nuevo')
		),
	},
	{
		key: 'tipo-establecimiento',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-establecimiento`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/tipo-establecimiento')
		),
	},
	{
		key: 'tipo-establecimiento.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-establecimiento/lista`,
		component: React.lazy(() =>
			import(
				'views/app-views/configuracion/maestros/tipo-establecimiento/lista'
			)
		),
	},
	{
		key: 'tipo-establecimiento.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-establecimiento/nuevo`,
		component: React.lazy(() =>
			import(
				'views/app-views/configuracion/maestros/tipo-establecimiento/nuevo'
			)
		),
	},
	{
		key: 'empresa',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/empresa`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/empresa')
		),
	},
	{
		key: 'empresa.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/empresa/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/empresa/lista')
		),
	},
	{
		key: 'empresa.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/empresa/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/empresa/nuevo')
		),
	},
	{
		key: 'tipo-muestra',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-muestra`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/tipo-muestra')
		),
	},
	{
		key: 'tipo-muestra.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-muestra/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/tipo-muestra/lista')
		),
	},
	{
		key: 'tipo-muestra.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-muestra/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/tipo-muestra/nuevo')
		),
	},
	{
		key: 'tipo-informe',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-informe`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/tipo-informe')
		),
	},
	{
		key: 'tipo-informe.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-informe/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/tipo-informe/lista')
		),
	},
	{
		key: 'tipo-informe.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-informe/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/tipo-informe/nuevo')
		),
	},
	{
		key: 'sustancia-prohibida',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/sustancia-prohibida`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/sustancia-prohibida')
		),
	},
	{
		key: 'sustancia-prohibida.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/sustancia-prohibida/lista`,
		component: React.lazy(() =>
			import(
				'views/app-views/configuracion/maestros/sustancia-prohibida/lista'
			)
		),
	},
	{
		key: 'sustancia-prohibida.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/sustancia-prohibida/nuevo`,
		component: React.lazy(() =>
			import(
				'views/app-views/configuracion/maestros/sustancia-prohibida/nuevo'
			)
		),
	},
	{
		key: 'inspector-sernapesca',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/inspector-sernapesca`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/inspector-sernapesca')
		),
	},
	{
		key: 'inspector-sernapesca.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/inspector-sernapesca/lista`,
		component: React.lazy(() =>
			import(
				'views/app-views/configuracion/maestros/inspector-sernapesca/lista'
			)
		),
	},
	{
		key: 'inspector-sernapesca.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/inspector-sernapesca/nuevo`,
		component: React.lazy(() =>
			import(
				'views/app-views/configuracion/maestros/inspector-sernapesca/nuevo'
			)
		),
	},
	{
		key: 'programa',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/programa`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/programa')
		),
	},
	{
		key: 'programa.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/programa/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/programa/lista')
		),
	},
	{
		key: 'programa.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/programa/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/programa/nuevo')
		),
	},
	{
		key: 'subprograma',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/subprograma`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/subprograma')
		),
	},
	{
		key: 'subprograma.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/subprograma/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/subprograma/lista')
		),
	},
	{
		key: 'subprograma.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/subprograma/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/subprograma/nuevo')
		),
	},
	{
		key: 'personal-empresa',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/personal-empresa`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/personal-empresa')
		),
	},
	{
		key: 'personal-empresa.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/personal-empresa/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/personal-empresa/lista')
		),
	},
	{
		key: 'personal-empresa.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/personal-empresa/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/personal-empresa/nuevo')
		),
	},
	{
		key: 'almacen',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/almacenes`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/almacen')
		),
	},
	{
		key: 'almacen.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/almacenes/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/almacen/lista')
		),
	},
	{
		key: 'almacen.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/almacenes/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/almacen/nuevo')
		),
	},
	{
		key: 'matriz',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/matriz`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/matriz')
		),
	},
	{
		key: 'matriz.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/matriz/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/matriz/lista')
		),
	},
	{
		key: 'matriz.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/matriz/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/matriz/nuevo')
		),
	},
	{
		key: 'metodo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/metodo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/metodo')
		),
	},
	{
		key: 'metodo.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/metodo/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/metodo/lista')
		),
	},
	{
		key: 'metodo.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/metodo/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/metodo/nuevo')
		),
	},
	{
		key: 'analito',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/analito`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/analito')
		),
	},
	{
		key: 'analito.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/analito/lista`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/analito/lista')
		),
	},
	{
		key: 'analito.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/analito/nuevo`,
		component: React.lazy(() =>
			import('views/app-views/configuracion/maestros/analito/nuevo')
		),
	},
];

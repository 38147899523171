import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EtapaCultivoService from 'services/EtapaCultivoService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	etapaCultivoEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	etapaCultivoDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createEtapaCultivo = createAsyncThunk(
	'etapaCultivo/createEtapaCultivo',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EtapaCultivoService.createEtapaCultivo,
			rejectWithValue
		);
	}
);

export const editEtapaCultivo = createAsyncThunk(
	'etapaCultivo/editEtapaCultivo',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EtapaCultivoService.editEtapaCultivo,
			rejectWithValue
		);
	}
);

export const deleteEtapaCultivo = createAsyncThunk(
	'etapaCultivo/deleteEtapaCultivo',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EtapaCultivoService.deleteEtapaCultivo,
			rejectWithValue
		);
	}
);

export const etapaCultivoSlice = createSlice({
	name: 'especie',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.etapaCultivoEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.etapaCultivoDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createEtapaCultivo.pending, state => {
				state.messagePopup = 'Creando etapa de cultivo, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createEtapaCultivo.fulfilled, state => {
				state.loading = false;
				state.redirect = '/app/configuracion/maestros/etapa-cultivo/lista';
				state.messagePopup = 'Se ha registrado la nueva etapa de cultivo.';
				state.messageType = 'success';
			})
			.addCase(createEtapaCultivo.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editEtapaCultivo.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.etapaCultivoEdited = null;
			})
			.addCase(editEtapaCultivo.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Etapa cultivo modificada';
				state.messageType = 'success';
				state.etapaCultivoEdited = action.payload;
			})
			.addCase(editEtapaCultivo.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.etapaCultivoEdited = null;
			})
			.addCase(deleteEtapaCultivo.pending, state => {
				state.messagePopup =
					'Eliminando etapa cultivo, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.etapaCultivoDeleted = false;
			})
			.addCase(deleteEtapaCultivo.fulfilled, (state, action) => {
				state.messagePopup = 'Etapa cultivo eliminada';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.etapaCultivoDeleted = true;
			})
			.addCase(deleteEtapaCultivo.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.etapaCultivoDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = etapaCultivoSlice.actions;

export default etapaCultivoSlice.reducer;

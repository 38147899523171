import {
	SIDE_NAV_LIGHT,
	NAV_TYPE_SIDE,
	DIR_LTR,
} from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig';

export const APP_NAME = 'FARMAVET';
export const API_BASE_URL = `${env.API_ENDPOINT_URL}${env.API_CLIENT_URL}`;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const DEFAULT_AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/modulos/recepcion/lista`;
export const RECEPCION_AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/modulos/recepcion/lista`;
export const MOLIENDA_AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/modulos/molienda`;
export const TECNICO_AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/modulos/extraccion`;
export const ANALISTA_AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/modulos/analisis/asignacion`;
export const COORDINADOR_AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/modulos/coordinacion/asignacion`;
export const SECRETARIA_AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/modulos/resultado`;
export const ADMINISTRADOR_AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/administracion/usuarios/lista`;

export const AUTHENTICATED_ENTRIES_BY_ROLE = {
	administrador: ADMINISTRADOR_AUTHENTICATED_ENTRY,
	analista: ANALISTA_AUTHENTICATED_ENTRY,
	coordinador: COORDINADOR_AUTHENTICATED_ENTRY,
	recepcion: RECEPCION_AUTHENTICATED_ENTRY,
	secretaria: SECRETARIA_AUTHENTICATED_ENTRY,
	tecnico: TECNICO_AUTHENTICATED_ENTRY,
	molienda: MOLIENDA_AUTHENTICATED_ENTRY,
};

export const MOBILE_PREFIX_PATH = '/mobile';
export const MOBILE_AUTH_PREFIX_PATH = '/mobile-auth';

export const UNAUTHENTICATED_ENTRY = '/login';
export const REDIRECT_URL_KEY = 'redirect';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'es',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#004B93',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false,
};

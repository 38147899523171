import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EspecieService from 'services/EspecieService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	especieEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	especieDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createEspecie = createAsyncThunk(
	'especie/createEspecie',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EspecieService.createEspecie,
			rejectWithValue
		);
	}
);

export const editEspecie = createAsyncThunk(
	'especie/editEspecie',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EspecieService.editEspecie,
			rejectWithValue
		);
	}
);

export const deleteEspecie = createAsyncThunk(
	'especie/deleteEspecie',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EspecieService.deleteEspecie,
			rejectWithValue
		);
	}
);

export const especieSlice = createSlice({
	name: 'especie',
	initialState,
	reducers: {
		cleanRedirect: (state, action) => {
			state.redirect = '';
		},
		disableMessagePopup: (state, action) => {
			state.messagePopup = '';
		},
		rowEdited: (state, action) => {
			state.especieEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: (state, action) => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: (state, action) => {
			state.especieDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createEspecie.pending, state => {
				state.messagePopup = 'Creando especie, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createEspecie.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/app/configuracion/maestros/especie/lista';
				state.messagePopup = 'Se ha registrado una nueva especie.';
				state.messageType = 'success';
			})
			.addCase(createEspecie.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editEspecie.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.especieEdited = null;
			})
			.addCase(editEspecie.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Especie modificada';
				state.messageType = 'success';
				state.especieEdited = action.payload;
			})
			.addCase(editEspecie.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.especieEdited = null;
			})
			.addCase(deleteEspecie.pending, state => {
				state.messagePopup = 'Eliminando especie, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.especieDeleted = false;
			})
			.addCase(deleteEspecie.fulfilled, (state, action) => {
				state.messagePopup = 'Especie eliminada';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.especieDeleted = true;
			})
			.addCase(deleteEspecie.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.especieDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = especieSlice.actions;

export default especieSlice.reducer;

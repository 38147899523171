import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import qs from 'qs';

const TipoEstablecimientoService = {};

TipoEstablecimientoService.fetchTipoEstablecimiento = query => {
	const fetchQuery = qs.stringify(query, { encodeValuesOnly: true });
	return fetch(
		`${API_BASE_URL}/tipo-establecimientos${query ? '?' + fetchQuery : ''}`,
		{
			method: 'GET',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			},
		}
	);
};

TipoEstablecimientoService.createTipoEstablecimiento = dataThunk => {
	const data = dataThunk;

	return fetch(`${API_BASE_URL}/tipo-establecimientos`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({ data }),
	});
};

TipoEstablecimientoService.editTipoEstablecimiento = dataThunk => {
	const { id, data } = dataThunk;

	return fetch(`${API_BASE_URL}/tipo-establecimientos/${id}`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({ data }),
	});
};

TipoEstablecimientoService.deleteTipoEstablecimiento = dataThunk => {
	const id = dataThunk;

	return fetch(`${API_BASE_URL}/tipo-establecimientos/${id}`, {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

export default TipoEstablecimientoService;

import { createSlice } from '@reduxjs/toolkit';

const mobileSlice = createSlice({
	name: 'mobile',
	initialState: false,
	reducers: {
		setMobileMode: () => true,
		setDesktopMode: () => false,
		toggleMobileMode: state => !state,
	},
});

export const { setMobileMode, setDesktopMode, toggleMobileMode } =
	mobileSlice.actions;
export default mobileSlice.reducer;

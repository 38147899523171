import React from 'react';
import { Routes as RouterRoutes, Route, Navigate } from 'react-router-dom';
import {
	DEFAULT_AUTHENTICATED_ENTRY,
	MOBILE_PREFIX_PATH,
} from 'configs/AppConfig';
import {
	mobileProtectedRoutes,
	mobilePublicRoutes,
	protectedRoutes,
	publicRoutes,
} from 'configs/RoutesConfig';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import AppRoute from './AppRoute';
import MobileProtectedRoute from './MobileProtectedRoute';
import MobilePublicRoute from './MobilePublicRoute';
import { useSelector } from 'react-redux';
import { useLocalStorage } from '@uidotdev/usehooks';

const Routes = () => {
	const isMobileLogin = useSelector(state => state.mobile);

	const [user] = useLocalStorage('me');

	return (
		<RouterRoutes>
			{isMobileLogin ? (
				<>
					<Route path='/' element={<MobileProtectedRoute />}>
						{!!user && (
							<>
								<Route
									path='/mobile'
									element={
										<Navigate
											replace
											to={`${MOBILE_PREFIX_PATH}/${user.role.type}`}
										/>
									}
								/>
								{mobileProtectedRoutes.map((route, index) => {
									return (
										<Route
											key={route.key + index}
											path={route.path}
											element={
												<AppRoute
													routeKey={route.key}
													component={route.component}
													{...route.meta}
												/>
											}
										/>
									);
								})}
							</>
						)}
					</Route>
					<Route path='/' element={<MobilePublicRoute />}>
						{mobilePublicRoutes.map(route => {
							return (
								<Route
									key={route.path}
									path={route.path}
									element={
										<AppRoute
											routeKey={route.key}
											component={route.component}
											{...route.meta}
										/>
									}
								/>
							);
						})}
					</Route>
					<Route path='*' element={<Navigate to='/' replace />} />
				</>
			) : (
				<>
					<Route path='/' element={<ProtectedRoute />}>
						<Route
							path='/'
							element={
								<Navigate replace to={DEFAULT_AUTHENTICATED_ENTRY} />
							}
						/>
						{protectedRoutes.map((route, index) => {
							return (
								<Route
									key={route.key + index}
									path={route.path}
									element={
										<AppRoute
											routeKey={route.key}
											component={route.component}
											{...route.meta}
										/>
									}
								/>
							);
						})}
					</Route>
					<Route path='/' element={<PublicRoute />}>
						{publicRoutes.map(route => {
							return (
								<Route
									key={route.path}
									path={route.path}
									element={
										<AppRoute
											routeKey={route.key}
											component={route.component}
											{...route.meta}
										/>
									}
								/>
							);
						})}
					</Route>
					<Route path='*' element={<Navigate to='/' replace />} />
				</>
			)}
		</RouterRoutes>
	);
};

export default Routes;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GrupoEspecieService from 'services/GrupoEspecieService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	grupoEspecieEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	grupoEspecieDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createGrupoEspecie = createAsyncThunk(
	'grupoEspecie/createGrupoEspecie',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			GrupoEspecieService.createGrupoEspecie,
			rejectWithValue
		);
	}
);

export const editGrupoEspecie = createAsyncThunk(
	'grupoEspecie/editGrupoEspecie',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			GrupoEspecieService.editGrupoEspecie,
			rejectWithValue
		);
	}
);

export const deleteGrupoEspecie = createAsyncThunk(
	'grupoEspecie/deleteGrupoEspecie',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			GrupoEspecieService.deleteGrupoEspecie,
			rejectWithValue
		);
	}
);

export const grupoEspecieSlice = createSlice({
	name: 'grupoEspecie',
	initialState,
	reducers: {
		cleanRedirect: (state, action) => {
			state.redirect = '';
		},
		disableMessagePopup: (state, action) => {
			state.messagePopup = '';
		},
		rowEdited: (state, action) => {
			state.grupoEspecieEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: (state, action) => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: (state, action) => {
			state.grupoEspecieDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createGrupoEspecie.pending, state => {
				state.messagePopup = 'Creando grupo especie, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createGrupoEspecie.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/app/configuracion/maestros/grupo-especie/lista';
				state.messagePopup = 'Se ha registrado un nuevo grupo especie.';
				state.messageType = 'success';
			})
			.addCase(createGrupoEspecie.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editGrupoEspecie.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.grupoEspecieEdited = null;
			})
			.addCase(editGrupoEspecie.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Grupo especie modificado';
				state.messageType = 'success';
				state.grupoEspecieEdited = action.payload;
			})
			.addCase(editGrupoEspecie.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.grupoEspecieEdited = null;
			})
			.addCase(deleteGrupoEspecie.pending, state => {
				state.messagePopup =
					'Eliminando grupo especie, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.grupoEspecieDeleted = false;
			})
			.addCase(deleteGrupoEspecie.fulfilled, (state, action) => {
				state.messagePopup = 'Grupo especie eliminado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.grupoEspecieDeleted = true;
			})
			.addCase(deleteGrupoEspecie.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.grupoEspecieDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = grupoEspecieSlice.actions;

export default grupoEspecieSlice.reducer;

import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import qs from 'qs';

const MatrizService = {};

MatrizService.fetchMatriz = query => {
	const fetchQuery = qs.stringify(query, { encodeValuesOnly: true });
	return fetch(`${API_BASE_URL}/matrices${query ? '?' + fetchQuery : ''}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

MatrizService.createMatriz = dataThunk => {
	const data = dataThunk;

	return fetch(`${API_BASE_URL}/matrices`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({ data }),
	});
};

MatrizService.editMatriz = dataThunk => {
	const { id, data } = dataThunk;

	return fetch(`${API_BASE_URL}/matrices/${id}`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({ data }),
	});
};

MatrizService.deleteMatriz = dataThunk => {
	const id = dataThunk;

	return fetch(`${API_BASE_URL}/matrices/${id}`, {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

export default MatrizService;

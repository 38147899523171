import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import qs from 'qs';

const UsuarioService = {};

UsuarioService.fetchUsuario = query => {
	const fetchQuery = qs.stringify(query, { encodeValuesOnly: true });

	return fetch(`${API_BASE_URL}/users${query ? '?' + fetchQuery : ''}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			...(localStorage.getItem(AUTH_TOKEN) !== null
				? { Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}` }
				: {}),
		},
	});
};

UsuarioService.createUsuario = dataThunk => {
	const data = dataThunk;

	return fetch(`${API_BASE_URL}/users`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: data,
	});
};

UsuarioService.editUsuario = dataThunk => {
	const { id, data } = dataThunk;

	return fetch(`${API_BASE_URL}/users/${id}`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: data,
	});
};

UsuarioService.uploadAvatar = dataThunk => {
	return fetch(`${API_BASE_URL}/upload`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: dataThunk,
	});
};

UsuarioService.download = url => {
	return fetch(`${API_BASE_URL}/upload/download/${encodeURIComponent(url)}`, {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

UsuarioService.getAvatar = id => {
	return fetch(`${API_BASE_URL}/users-permissions/avatar/${id}`);
};

UsuarioService.deleteUsuario = dataThunk => {
	const id = dataThunk;

	return fetch(`${API_BASE_URL}/users/${id}`, {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

export default UsuarioService;

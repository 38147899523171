import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import qs from 'qs';

const PersonalEmpresaService = {};

PersonalEmpresaService.fetchPersonalEmpresa = query => {
	const fetchQuery = qs.stringify(query, { encodeValuesOnly: true });
	return fetch(`${API_BASE_URL}/personales${query ? '?' + fetchQuery : ''}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

PersonalEmpresaService.createPersonalEmpresa = dataThunk => {
	const data = dataThunk;

	return fetch(`${API_BASE_URL}/personales`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({ data }),
	});
};

PersonalEmpresaService.editPersonalEmpresa = dataThunk => {
	const { id, data } = dataThunk;

	return fetch(`${API_BASE_URL}/personales/${id}`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({ data }),
	});
};

PersonalEmpresaService.deletePersonalEmpresa = dataThunk => {
	const id = dataThunk;

	return fetch(`${API_BASE_URL}/personales/${id}`, {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

export default PersonalEmpresaService;

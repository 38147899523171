import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SustanciaService from 'services/SustanciaService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	sustanciaEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	sustanciaDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createSustancia = createAsyncThunk(
	'sustancia/createSustancia',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			SustanciaService.createSustancia,
			rejectWithValue
		);
	}
);

export const editSustancia = createAsyncThunk(
	'sustancia/editSustancia',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			SustanciaService.editSustancia,
			rejectWithValue
		);
	}
);

export const deleteSustancia = createAsyncThunk(
	'sustancia/deleteSustancia',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			SustanciaService.deleteSustancia,
			rejectWithValue
		);
	}
);

export const sustanciaSlice = createSlice({
	name: 'sustancia',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.sustanciaEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.sustanciaDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createSustancia.pending, state => {
				state.messagePopup = 'Creando sustancia, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createSustancia.fulfilled, state => {
				state.loading = false;
				state.redirect =
					'/app/configuracion/maestros/sustancia-prohibida/lista';
				state.messagePopup = 'Se ha registrado una nueva sustancia.';
				state.messageType = 'success';
			})
			.addCase(createSustancia.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editSustancia.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.sustanciaEdited = null;
			})
			.addCase(editSustancia.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Sustancia prohibida modificada';
				state.messageType = 'success';
				state.sustanciaEdited = action.payload;
			})
			.addCase(editSustancia.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.sustanciaEdited = null;
			})
			.addCase(deleteSustancia.pending, state => {
				state.messagePopup =
					'Eliminando sustancia prohibida, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.sustanciaDeleted = false;
			})
			.addCase(deleteSustancia.fulfilled, (state, action) => {
				state.messagePopup = 'Sustancia prohibida eliminada';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.sustanciaDeleted = true;
			})
			.addCase(deleteSustancia.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.sustanciaDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = sustanciaSlice.actions;

export default sustanciaSlice.reducer;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MOBILE_PREFIX_PATH } from 'configs/AppConfig';
import { useLocalStorage } from '@uidotdev/usehooks';

const MobilePublicRoute = () => {
	const { token } = useSelector(state => state.auth);

	const [user] = useLocalStorage('me');

	return token ? (
		<Navigate to={`${MOBILE_PREFIX_PATH}/${user.role.type}`} />
	) : (
		<Outlet />
	);
};

export default MobilePublicRoute;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EstablecimientoService from 'services/EstablecimientoService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	establecimientoEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	establecimientoDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createEstablecimiento = createAsyncThunk(
	'establecimiento/createEstablecimiento',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EstablecimientoService.createEstablecimiento,
			rejectWithValue
		);
	}
);

export const editEstablecimiento = createAsyncThunk(
	'establecimiento/editEstablecimiento',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EstablecimientoService.editEstablecimiento,
			rejectWithValue
		);
	}
);

export const deleteEstablecimiento = createAsyncThunk(
	'establecimiento/deleteEstablecimiento',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EstablecimientoService.deleteEstablecimiento,
			rejectWithValue
		);
	}
);

export const establecimientoSlice = createSlice({
	name: 'establecimiento',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.establecimientoEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.establecimientoDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createEstablecimiento.pending, state => {
				state.messagePopup = 'Creando establecimiento, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createEstablecimiento.fulfilled, state => {
				state.loading = false;
				state.redirect =
					'/app/configuracion/maestros/establecimiento/lista';
				state.messagePopup = 'Se ha registrado un nuevo establecimiento.';
				state.messageType = 'success';
			})
			.addCase(createEstablecimiento.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editEstablecimiento.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.establecimientoEdited = null;
			})
			.addCase(editEstablecimiento.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Establecimiento modificado';
				state.messageType = 'success';
				state.establecimientoEdited = action.payload;
			})
			.addCase(editEstablecimiento.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.establecimientoEdited = null;
			})
			.addCase(deleteEstablecimiento.pending, state => {
				state.messagePopup =
					'Eliminando establecimiento, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.establecimientoDeleted = false;
			})
			.addCase(deleteEstablecimiento.fulfilled, (state, action) => {
				state.messagePopup = 'Establecimiento eliminado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.establecimientoDeleted = true;
			})
			.addCase(deleteEstablecimiento.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.establecimientoDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = establecimientoSlice.actions;

export default establecimientoSlice.reducer;

import { message } from 'antd';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'configs/EnvironmentConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import qs from 'qs';

export const procesosApi = createApi({
	reducerPath: 'procesosApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${env.API_ENDPOINT_URL}${env.API_CLIENT_URL}`,
		prepareHeaders: (headers, { getState }) => {
			const token =
				localStorage.getItem(AUTH_TOKEN) || getState().auth.token;

			headers.set('Content-Type', 'application/json');
			headers.set('Accept', 'application/json');

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),

	// default configuration
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	refetchOnFocus: true,

	//endpoints
	endpoints: build => ({
		getProcesos: build.query({
			query: query => {
				return {
					url: `/procesos${
						query
							? `?${qs.stringify(query, {
									encodeValuesOnly: true,
							  })}`
							: ''
					}`,
					method: 'GET',
				};
			},
			transformResponse: response => response.data,
			providesTags: result =>
				// is result available?
				result
					? // successful query
					  [
							...result.map(({ id }) => ({ type: 'Procesos', id })),
							{ type: 'Procesos', id: 'LIST' },
					  ]
					: // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
					  [{ type: 'Procesos', id: 'LIST' }],
		}),
		getProceso: build.query({
			query: ({ id, query }) => {
				return {
					url: `/procesos/${id}${
						query
							? `?${qs.stringify(query, {
									encodeValuesOnly: true,
							  })}`
							: ''
					}`,
					method: 'GET',
				};
			},
			transformResponse: response => response.data,
			providesTags: (result, error, id) => [{ type: 'Procesos', id }],
		}),
		addProceso: build.mutation({
			query: data => {
				const user = JSON.parse(localStorage.getItem('me'));
				return {
					url: `/procesos`,
					method: 'POST',
					body: JSON.stringify({
						data: {
							...data,
							created_by_user: user.id,
							updated_by_user: user.id,
						},
					}),
				};
			},
			onQueryStarted: async ({ data }, { dispatch, queryFulfilled }) => {
				// try catch with antd message when fulfilled and rejected
				try {
					await queryFulfilled;
					message.success(`Proceso creado correctamente`);
				} catch (error) {
					message.error(`Error al crear el proceso. ${error}`);
				}
			},
			invalidatesTags: [{ type: 'Procesos', id: 'LIST' }],
		}),
		updateProceso: build.mutation({
			query: ({ id, data }) => {
				const user = JSON.parse(localStorage.getItem('me'));
				return {
					url: `/procesos/${id}`,
					method: 'PUT',
					body: JSON.stringify({
						data: {
							...data,
							updated_by_user: user.id,
						},
					}),
				};
			},
			onQueryStarted: async ({ id, data }, { dispatch, queryFulfilled }) => {
				// try catch with antd message when fulfilled and rejected
				try {
					await queryFulfilled;
					message.success(`Proceso actualizado correctamente`);
				} catch (error) {
					message.error(`Error al actualizar el proceso. ${error}`);
				}
			},
			invalidatesTags: (result, error, { id }) => [{ type: 'Procesos', id }],
		}),
		deleteProceso: build.mutation({
			query: id => {
				return {
					url: `/procesos/${id}`,
					method: 'DELETE',
				};
			},
			onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
				// try catch with antd message when fulfilled and rejected
				try {
					await queryFulfilled;
					message.success(`Proceso eliminado correctamente`);
				} catch (error) {
					message.error(`Error al eliminar el proceso. ${error}`);
				}
			},
			invalidatesTags: (result, error, id) => [{ type: 'Procesos', id }],
		}),
	}),
});

export const {
	useGetProcesosQuery,
	useGetProcesoQuery,
	useAddProcesoMutation,
	useUpdateProcesoMutation,
	useDeleteProcesoMutation,
} = procesosApi;

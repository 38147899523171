import { AUTH_TOKEN } from 'constants/AuthConstant';
import store from '../store';
import { signOutSuccess } from 'store/slices/authSlice';
import { message } from 'antd';

const unauthorizedCode = [401, 403];
const errorCodes = [400, 409, 500];

export const responseSliceHandler = async (data, service, rejectWithValue) => {
	try {
		const response = await service(data);

		if (unauthorizedCode.includes(response.status)) {
			message.error('Debe autenticarse nuevamente');
			localStorage.removeItem(AUTH_TOKEN);
			store.dispatch(signOutSuccess());
		}

		const dataResponse = await response.json();

		if (errorCodes.includes(response.status)) {
			return rejectWithValue(dataResponse.error.message);
		}

		return dataResponse;
	} catch (err) {
		console.log('err: ', err);
		return rejectWithValue(err.response?.error?.message || 'Error');
	}
};

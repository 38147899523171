import fetch from 'auth/FetchInterceptor';

const AuthService = {};

AuthService.login = function (data) {
	return fetch({
		url: '/auth/local',
		method: 'post',
		data: data,
	});
};

AuthService.register = function (data) {
	return fetch({
		url: '/auth/local/register',
		method: 'post',
		data: data,
	});
};

AuthService.logout = function () {
	return fetch({
		url: '/auth/logout',
		method: 'post',
	});
};

AuthService.loginInOAuth = function () {
	return fetch({
		url: '/auth/loginInOAuth',
		method: 'post',
	});
};

AuthService.forgotPassword = function (email) {
	return fetch({
		url: '/auth/forgot-password',
		method: 'post',
		data: { email },
	});
};

AuthService.resetPassword = function (data) {
	return fetch({
		url: '/auth/reset-password',
		method: 'post',
		data: data,
	});
};

export default AuthService;

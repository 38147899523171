import { message } from 'antd';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from 'configs/EnvironmentConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import qs from 'qs';

export const muestrasApi = createApi({
	reducerPath: 'muestrasApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${env.API_ENDPOINT_URL}${env.API_CLIENT_URL}`,
		prepareHeaders: (headers, { getState }) => {
			const token =
				localStorage.getItem(AUTH_TOKEN) || getState().auth.token;

			headers.set('Content-Type', 'application/json');
			headers.set('Accept', 'application/json');

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),

	// default configuration
	refetchOnReconnect: true,
	refetchOnMountOrArgChange: true,
	refetchOnFocus: true,

	//endpoints
	endpoints: build => ({
		getMuestras: build.query({
			query: query => {
				return {
					url: `/muestras${
						query
							? `?${qs.stringify(query, {
									encodeValuesOnly: true,
							  })}`
							: ''
					}`,
					method: 'GET',
				};
			},
			transformResponse: response => response.data,
			providesTags: result =>
				// is result available?
				result
					? // successful query
					  [
							...result.map(({ id }) => ({ type: 'Muestras', id })),
							{ type: 'Muestras', id: 'LIST' },
					  ]
					: // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
					  [{ type: 'Muestras', id: 'LIST' }],
		}),
		getMuestra: build.query({
			query: id => {
				return {
					url: `/muestras/${id}`,
					method: 'GET',
				};
			},
			transformResponse: response => response.data,
			providesTags: (result, error, id) => [{ type: 'Muestras', id }],
		}),
		addMuestra: build.mutation({
			query: data => {
				const user = JSON.parse(localStorage.getItem('me'));
				return {
					url: `/muestras`,
					method: 'POST',
					body: JSON.stringify({
						data: {
							...data,
							created_by_user: user.id,
						},
					}),
				};
			},
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				// try catch with antd message when fulfilled and rejected
				try {
					await queryFulfilled;
					message.success('Muestra agregada exitosamente');
				} catch (error) {
					message.error('Error al agregar muestra');
				}
			},
			invalidatesTags: [{ type: 'Muestras', id: 'LIST' }],
		}),
		updateMuestra: build.mutation({
			query: ({ id, ...data }) => {
				const user = JSON.parse(localStorage.getItem('me'));
				return {
					url: `/muestras/${id}`,
					method: 'PUT',
					body: JSON.stringify({
						data: {
							...data,
							updated_by_user: user.id,
						},
					}),
				};
			},
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				// try catch with antd message when fulfilled and rejected
				try {
					await queryFulfilled;
					message.success('Muestra actualizada exitosamente');
				} catch (error) {
					message.error('Error al actualizar muestra');
				}
			},
			invalidatesTags: (result, error, { id }) => [{ type: 'Muestras', id }],
		}),
		updateBulkMuestras: build.mutation({
			query: ({ ids, data }) => {
				const user = JSON.parse(localStorage.getItem('me'));
				return {
					url: `/muestras-bulk`,
					method: 'PUT',
					body: JSON.stringify({
						ids,
						data: {
							...data,
							updated_by_user: user.id,
						},
					}),
				};
			},
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				// try catch with antd message when fulfilled and rejected
				try {
					await queryFulfilled;
					message.success('Muestras actualizadas exitosamente');
				} catch (error) {
					message.error('Error al actualizar muestras');
				}
			},
			invalidatesTags: [{ type: 'Muestras', id: 'LIST' }],
		}),
		deleteMuestra: build.mutation({
			query: id => {
				return {
					url: `/muestras/${id}`,
					method: 'DELETE',
				};
			},
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				// try catch with antd message when fulfilled and rejected
				try {
					await queryFulfilled;
					message.success('Muestra eliminada exitosamente');
				} catch (error) {
					message.error('Error al eliminar muestra');
				}
			},
			invalidatesTags: (result, error, id) => [{ type: 'Muestras', id }],
		}),
	}),
});

export const {
	useGetMuestrasQuery,
	useGetMuestraQuery,
	useAddMuestraMutation,
	useUpdateMuestraMutation,
	useUpdateBulkMuestrasMutation,
	useDeleteMuestraMutation,
} = muestrasApi;

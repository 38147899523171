const dev = {
	API_CLIENT_URL: process.env.REACT_APP_API_CLIENT_PATH || '/api',
	API_ENDPOINT_URL: process.env.REACT_APP_API_URL || 'http://localhost:1337',
};

const prod = {
	API_CLIENT_URL: process.env.REACT_APP_API_CLIENT_PATH || '/api',
	API_ENDPOINT_URL: process.env.REACT_APP_API_URL || 'http://localhost:1337',
};

const test = {
	API_CLIENT_URL: process.env.REACT_APP_API_CLIENT_PATH || '/api',
	API_ENDPOINT_URL: process.env.REACT_APP_API_URL || 'http://localhost:1337',
};

const getEnv = () => {
	switch (process.env.REACT_APP_NODE_ENV) {
		case 'development':
			return dev;
		case 'production':
			return prod;
		case 'test':
			return test;
		default:
			return dev;
	}
};

export const env = getEnv();

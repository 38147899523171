import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import qs from 'qs';

const ProgramaService = {};

ProgramaService.fetchProgramas = query => {
	const fetchQuery = qs.stringify(query, { encodeValuesOnly: true });
	return fetch(`${API_BASE_URL}/programas${query ? '?' + fetchQuery : ''}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

ProgramaService.fetchPrograma = (id, query) => {
	const fetchQuery = qs.stringify(query, { encodeValuesOnly: true });
	return fetch(
		`${API_BASE_URL}/programas/${id}${query ? '?' + fetchQuery : ''}`,
		{
			method: 'GET',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			},
		}
	);
};

ProgramaService.createPrograma = dataThunk => {
	const data = dataThunk;

	return fetch(`${API_BASE_URL}/programas`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({
			data: {
				...data,
				subprogramas: data.subprogramas
					? data.subprogramas.map(row => row[0])
					: null,
			},
		}),
	});
};

ProgramaService.editPrograma = dataThunk => {
	const { id, data } = dataThunk;

	return fetch(`${API_BASE_URL}/programas/${id}`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({ data }),
	});
};

ProgramaService.deletePrograma = dataThunk => {
	const id = dataThunk;

	return fetch(`${API_BASE_URL}/programas/${id}`, {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

export default ProgramaService;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SubprogramaService from 'services/SubprogramaService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	subprogramaEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	subprogramaDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createSubprograma = createAsyncThunk(
	'subprograma/createSubprograma',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			SubprogramaService.createSubprograma,
			rejectWithValue
		);
	}
);

export const editSubprograma = createAsyncThunk(
	'subprograma/editSubprograma',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			SubprogramaService.editSubprograma,
			rejectWithValue
		);
	}
);

export const deleteSubprograma = createAsyncThunk(
	'subprograma/deleteSubprograma',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			SubprogramaService.deleteSubprograma,
			rejectWithValue
		);
	}
);

export const resetearCorrelativo = createAsyncThunk(
	'subprograma/resetearCorrelativo',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			SubprogramaService.resetearCorrelativo,
			rejectWithValue
		);
	}
);

export const subprogramaSlice = createSlice({
	name: 'subprograma',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.subprogramaEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.subprogramaDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createSubprograma.pending, state => {
				state.messagePopup = 'Creando subprograma, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createSubprograma.fulfilled, state => {
				state.loading = false;
				state.redirect = '/app/configuracion/maestros/subprograma/lista';
				state.messagePopup = 'Se ha registrado un nuevo subprograma.';
				state.messageType = 'success';
			})
			.addCase(createSubprograma.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editSubprograma.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.subprogramaEdited = null;
			})
			.addCase(editSubprograma.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Subprograma modificado';
				state.messageType = 'success';
				state.subprogramaEdited = action.payload;
			})
			.addCase(editSubprograma.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.subprogramaEdited = null;
			})
			.addCase(deleteSubprograma.pending, state => {
				state.messagePopup = 'Eliminando subprograma, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.subprogramaDeleted = false;
			})
			.addCase(deleteSubprograma.fulfilled, (state, action) => {
				state.messagePopup = 'Subprograma eliminado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.subprogramaDeleted = true;
			})
			.addCase(deleteSubprograma.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.subprogramaDeleted = true;
			})
			.addCase(resetearCorrelativo.pending, state => {
				state.messagePopup = 'Reseteando correlativo, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
			})
			.addCase(resetearCorrelativo.fulfilled, state => {
				state.messagePopup = 'Correlativo reseteado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
			})
			.addCase(resetearCorrelativo.rejected, (state, action) => {
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = subprogramaSlice.actions;

export default subprogramaSlice.reducer;

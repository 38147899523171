import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MuestraService from 'services/MuestraService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	muestraEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	muestraDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createMuestra = createAsyncThunk(
	'muestra/createMuestra',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			MuestraService.createMuestra,
			rejectWithValue
		);
	}
);

export const editMuestra = createAsyncThunk(
	'muestra/editMuestra',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			MuestraService.editMuestra,
			rejectWithValue
		);
	}
);

export const editBulkMuestra = createAsyncThunk(
	'muestra/editBulkMuestra',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			MuestraService.editBulkMuestra,
			rejectWithValue
		);
	}
);

export const deleteMuestra = createAsyncThunk(
	'muestra/deleteMuestra',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			MuestraService.deleteMuestra,
			rejectWithValue
		);
	}
);

export const muestraSlice = createSlice({
	name: 'muestra',
	initialState,
	reducers: {
		cleanRedirect: (state, action) => {
			state.redirect = '';
		},
		disableMessagePopup: (state, action) => {
			state.messagePopup = '';
		},
		rowEdited: (state, action) => {
			state.muestraEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: (state, action) => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: (state, action) => {
			state.muestraDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createMuestra.pending, state => {
				state.messagePopup = 'Creando muestra, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createMuestra.fulfilled, (state, action) => {
				state.loading = false;
				state.messagePopup = 'Se ha registrado una nueva muestra.';
				state.messageType = 'success';
			})
			.addCase(createMuestra.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editMuestra.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.muestraEdited = null;
			})
			.addCase(editMuestra.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Guardados los cambios a la muestra';
				state.messageType = 'success';
				state.muestraEdited = action.payload;
			})
			.addCase(editMuestra.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.muestraEdited = null;
			})
			.addCase(editBulkMuestra.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.muestraEdited = null;
			})
			.addCase(editBulkMuestra.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Guardados los cambios a las muestras';
				state.messageType = 'success';
				state.muestraEdited = action.payload;
			})
			.addCase(editBulkMuestra.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.muestraEdited = null;
			})
			.addCase(deleteMuestra.pending, state => {
				state.messagePopup = '';
				state.onSubmitLoading = true;
				state.muestraDeleted = false;
			})
			.addCase(deleteMuestra.fulfilled, (state, action) => {
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.muestraDeleted = true;
			})
			.addCase(deleteMuestra.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.muestraDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = muestraSlice.actions;

export default muestraSlice.reducer;

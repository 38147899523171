import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PersonalEmpresaService from 'services/PersonalEmpresaService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	personalEmpresaEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	personalEmpresaDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createPersonalEmpresa = createAsyncThunk(
	'personalEmpresa/createPersonalEmpresa',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			PersonalEmpresaService.createPersonalEmpresa,
			rejectWithValue
		);
	}
);

export const editPersonalEmpresa = createAsyncThunk(
	'personalEmpresa/editPersonalEmpresa',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			PersonalEmpresaService.editPersonalEmpresa,
			rejectWithValue
		);
	}
);

export const deletePersonalEmpresa = createAsyncThunk(
	'personalEmpresa/deletePersonalEmpresa',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			PersonalEmpresaService.deletePersonalEmpresa,
			rejectWithValue
		);
	}
);

export const personalEmpresaSlice = createSlice({
	name: 'personalEmpresa',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.personalEmpresaEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.personalEmpresaDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createPersonalEmpresa.pending, state => {
				state.messagePopup = 'Creando personal empresa, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createPersonalEmpresa.fulfilled, state => {
				state.loading = false;
				state.redirect =
					'/app/configuracion/maestros/personal-empresa/lista';
				state.messagePopup = 'Se ha registrado un nuevo personal empresa.';
				state.messageType = 'success';
			})
			.addCase(createPersonalEmpresa.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editPersonalEmpresa.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.personalEmpresaEdited = null;
			})
			.addCase(editPersonalEmpresa.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Personal empresa modificado';
				state.messageType = 'success';
				state.personalEmpresaEdited = action.payload;
			})
			.addCase(editPersonalEmpresa.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.personalEmpresaEdited = null;
			})
			.addCase(deletePersonalEmpresa.pending, state => {
				state.messagePopup =
					'Eliminando personal empresa, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.personalEmpresaDeleted = false;
			})
			.addCase(deletePersonalEmpresa.fulfilled, (state, action) => {
				state.messagePopup = 'Personal empresa eliminado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.personalEmpresaDeleted = true;
			})
			.addCase(deletePersonalEmpresa.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.personalEmpresaDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = personalEmpresaSlice.actions;

export default personalEmpresaSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AlmacenService from 'services/AlmacenService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	almacenEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	almacenDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createAlmacen = createAsyncThunk(
	'almacen/createAlmacen',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			AlmacenService.createAlmacen,
			rejectWithValue
		);
	}
);

export const editAlmacen = createAsyncThunk(
	'almacen/editAlmacen',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			AlmacenService.editAlmacen,
			rejectWithValue
		);
	}
);

export const deleteAlmacen = createAsyncThunk(
	'almacen/deleteAlmacen',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			AlmacenService.deleteAlmacen,
			rejectWithValue
		);
	}
);

export const almacenSlice = createSlice({
	name: 'almacen',
	initialState,
	reducers: {
		cleanRedirect: (state, action) => {
			state.redirect = '';
		},
		disableMessagePopup: (state, action) => {
			state.messagePopup = '';
		},
		rowEdited: (state, action) => {
			state.almacenEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: (state, action) => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: (state, action) => {
			state.almacenDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createAlmacen.pending, state => {
				state.messagePopup = 'Creando almacen, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createAlmacen.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/app/configuracion/maestros/almacen/lista';
				state.messagePopup = 'Se ha registrado una nueva almacen.';
				state.messageType = 'success';
			})
			.addCase(createAlmacen.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editAlmacen.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.almacenEdited = null;
			})
			.addCase(editAlmacen.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Almacen modificado';
				state.messageType = 'success';
				state.almacenEdited = action.payload;
			})
			.addCase(editAlmacen.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.almacenEdited = null;
			})
			.addCase(deleteAlmacen.pending, state => {
				state.messagePopup = 'Eliminando almacen, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.almacenDeleted = false;
			})
			.addCase(deleteAlmacen.fulfilled, (state, action) => {
				state.messagePopup = 'Almacen eliminado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.almacenDeleted = true;
			})
			.addCase(deleteAlmacen.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.almacenDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = almacenSlice.actions;

export default almacenSlice.reducer;

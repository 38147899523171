import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EmpresaService from 'services/EmpresaService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	empresaEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	empresaDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createEmpresa = createAsyncThunk(
	'empresa/createEmpresa',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EmpresaService.createEmpresa,
			rejectWithValue
		);
	}
);

export const editEmpresa = createAsyncThunk(
	'empresa/editEmpresa',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EmpresaService.editEmpresa,
			rejectWithValue
		);
	}
);

export const deleteEmpresa = createAsyncThunk(
	'empresa/deleteEmpresa',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			EmpresaService.deleteEmpresa,
			rejectWithValue
		);
	}
);

export const empresaSlice = createSlice({
	name: 'empresa',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.empresaEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.empresaDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createEmpresa.pending, state => {
				state.messagePopup = 'Creando empresa, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createEmpresa.fulfilled, state => {
				state.loading = false;
				state.redirect = '/app/configuracion/maestros/empresa/lista';
				state.messagePopup = 'Se ha registrado una nueva empresa.';
				state.messageType = 'success';
			})
			.addCase(createEmpresa.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editEmpresa.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.empresaEdited = null;
			})
			.addCase(editEmpresa.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Empresa modificada';
				state.messageType = 'success';
				state.empresaEdited = action.payload;
			})
			.addCase(editEmpresa.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.empresaEdited = null;
			})
			.addCase(deleteEmpresa.pending, state => {
				state.messagePopup = 'Eliminando Empresa, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.empresaDeleted = false;
			})
			.addCase(deleteEmpresa.fulfilled, (state, action) => {
				state.messagePopup = 'Empresa eliminada';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.empresaDeleted = true;
			})
			.addCase(deleteEmpresa.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.empresaDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = empresaSlice.actions;

export default empresaSlice.reducer;

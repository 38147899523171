import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ProcesoService from 'services/ProcesoService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	procesoEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	procesoDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createProceso = createAsyncThunk(
	'proceso/createProceso',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			ProcesoService.createProceso,
			rejectWithValue
		);
	}
);

export const editProceso = createAsyncThunk(
	'proceso/editProceso',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			ProcesoService.editProceso,
			rejectWithValue
		);
	}
);

export const finishProceso = createAsyncThunk(
	'proceso/finishProceso',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			ProcesoService.finishProceso,
			rejectWithValue
		);
	}
);

export const editBulkProceso = createAsyncThunk(
	'proceso/editBulkProceso',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			ProcesoService.editBulkProceso,
			rejectWithValue
		);
	}
);

export const deleteProceso = createAsyncThunk(
	'proceso/deleteProceso',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			ProcesoService.deleteProceso,
			rejectWithValue
		);
	}
);

export const procesoSlice = createSlice({
	name: 'proceso',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.procesoEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.procesoDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createProceso.pending, state => {
				state.messagePopup = 'Creando proceso, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createProceso.fulfilled, state => {
				state.loading = false;
				state.redirect = '/app/modulos/recepcion/lista';
				state.messagePopup = 'Se ha registrado un nuevo proceso.';
				state.messageType = 'success';
			})
			.addCase(createProceso.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editProceso.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.procesoEdited = null;
			})
			.addCase(editProceso.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Se han guardado los cambios al proceso';
				state.messageType = 'success';
				state.procesoEdited = action.payload;
			})
			.addCase(editProceso.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.procesoEdited = null;
			})
			.addCase(finishProceso.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.procesoEdited = null;
			})
			.addCase(finishProceso.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Se han guardado los cambios al proceso';
				state.messageType = 'success';
				state.procesoEdited = action.payload;
			})
			.addCase(finishProceso.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.procesoEdited = null;
			})
			.addCase(editBulkProceso.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.procesoEdited = null;
			})
			.addCase(editBulkProceso.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Se han guardado los cambios a los procesos';
				state.messageType = 'success';
				state.procesoEdited = action.payload;
			})
			.addCase(editBulkProceso.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.procesoEdited = null;
			})
			.addCase(deleteProceso.pending, state => {
				state.messagePopup = '';
				state.onSubmitLoading = true;
				state.procesoDeleted = false;
			})
			.addCase(deleteProceso.fulfilled, state => {
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.procesoDeleted = true;
			})
			.addCase(deleteProceso.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.procesoDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = procesoSlice.actions;

export default procesoSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TipoInformeService from 'services/TipoInformeService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	tipoInformeEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	tipoInformeDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createTipoInforme = createAsyncThunk(
	'tipoInforme/createTipoInforme',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoInformeService.createTipoInforme,
			rejectWithValue
		);
	}
);

export const editTipoInforme = createAsyncThunk(
	'tipoInforme/editTipoInforme',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoInformeService.editTipoInforme,
			rejectWithValue
		);
	}
);

export const deleteTipoInforme = createAsyncThunk(
	'tipoInforme/deleteTipoInforme',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoInformeService.deleteTipoInforme,
			rejectWithValue
		);
	}
);

export const tipoInformeSlice = createSlice({
	name: 'tipoInforme',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.tipoInformeEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.tipoInformeDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createTipoInforme.pending, state => {
				state.messagePopup = 'Creando tipo de informe, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createTipoInforme.fulfilled, state => {
				state.loading = false;
				state.redirect = '/app/configuracion/maestros/tipo-informe/lista';
				state.messagePopup = 'Se ha registrado un nuevo tipo de informe.';
				state.messageType = 'success';
			})
			.addCase(createTipoInforme.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editTipoInforme.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.tipoInformeEdited = null;
			})
			.addCase(editTipoInforme.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Tipo informe modificado';
				state.messageType = 'success';
				state.tipoInformeEdited = action.payload;
			})
			.addCase(editTipoInforme.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.tipoInformeEdited = null;
			})
			.addCase(deleteTipoInforme.pending, state => {
				state.messagePopup = 'Eliminando tipo informe, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.tipoInformeDeleted = false;
			})
			.addCase(deleteTipoInforme.fulfilled, (state, action) => {
				state.messagePopup = 'Tipo informe eliminado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.tipoInformeDeleted = true;
			})
			.addCase(deleteTipoInforme.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.tipoInformeDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = tipoInformeSlice.actions;

export default tipoInformeSlice.reducer;

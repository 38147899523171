import { combineReducers } from 'redux';
import theme from './slices/themeSlice';
import auth from './slices/authSlice';
import tipoanalisis from './slices/tipoAnalisisSlice';
import especie from './slices/especieSlice';
import etapaCultivo from './slices/etapaCultivoSlice';
import tipoEstablecimiento from './slices/tipoEstablecimientoSlice';
import empresa from './slices/empresaSlice';
import tipoMuestra from './slices/tipoMuestraSlice';
import tipoInforme from './slices/tipoInformeSlice';
import sustancia from './slices/sustanciaSlice';
import inspectorSernapesca from './slices/inspectorSernapescaSlice';
import establecimiento from './slices/establecimientoSlice';
import proceso from './slices/recepcionSlice';
import programa from './slices/programaSlice';
import subprograma from './slices/subprogramaSlice';
import personalEmpresa from './slices/personalEmpresaSlice';
import almacen from './slices/almacenSlice';
import matriz from './slices/matrizSlice';
import metodo from './slices/metodoSlice';
import muestra from './slices/muestraSlice';
import grupoEspecie from './slices/grupoEspecieSlice';
import usuario from './slices/usuarioSlice';
import analito from './slices/analitoSlice';
import mobile from './slices/mobileSlice';
import { muestrasApi } from '../services/MuestrasApi';
import { procesosApi } from 'services/ProcesosApi';

const rootReducer = asyncReducers => (state, action) => {
	const combinedReducer = combineReducers({
		theme,
		auth,
		tipoanalisis,
		especie,
		etapaCultivo,
		tipoEstablecimiento,
		empresa,
		tipoMuestra,
		tipoInforme,
		sustancia,
		inspectorSernapesca,
		establecimiento,
		proceso,
		programa,
		subprograma,
		personalEmpresa,
		almacen,
		matriz,
		metodo,
		muestra,
		grupoEspecie,
		usuario,
		analito,
		mobile,
		[muestrasApi.reducerPath]: muestrasApi.reducer,
		[procesosApi.reducerPath]: procesosApi.reducer,
		...asyncReducers,
	});
	return combinedReducer(state, action);
};

export default rootReducer;

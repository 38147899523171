import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TipoEstablecimientoService from 'services/TipoEstablecimientoService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	tipoEstablecimientoEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	tipoEstablecimientoDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createTipoEstablecimiento = createAsyncThunk(
	'tipoEstablecimiento/createTipoEstablecimiento',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoEstablecimientoService.createTipoEstablecimiento,
			rejectWithValue
		);
	}
);

export const editTipoEstablecimiento = createAsyncThunk(
	'tipoEstablecimiento/editTipoEstablecimiento',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoEstablecimientoService.editTipoEstablecimiento,
			rejectWithValue
		);
	}
);

export const deleteTipoEstablecimiento = createAsyncThunk(
	'tipoEstablecimiento/deleteTipoEstablecimiento',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoEstablecimientoService.deleteTipoEstablecimiento,
			rejectWithValue
		);
	}
);

export const tipoEstablecimientoSlice = createSlice({
	name: 'tipoEstablecimiento',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.tipoEstablecimientoEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.tipoEstablecimientoDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createTipoEstablecimiento.pending, state => {
				state.messagePopup =
					'Creando tipo establecimiento, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createTipoEstablecimiento.fulfilled, state => {
				state.loading = false;
				state.redirect =
					'/app/configuracion/maestros/tipo-establecimiento/lista';
				state.messagePopup =
					'Se ha registrado un nuevo tipo de establecimiento.';
				state.messageType = 'success';
			})
			.addCase(createTipoEstablecimiento.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editTipoEstablecimiento.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.tipoEstablecimientoEdited = null;
			})
			.addCase(editTipoEstablecimiento.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Tipo establecimiento modificado';
				state.messageType = 'success';
				state.tipoEstablecimientoEdited = action.payload;
			})
			.addCase(editTipoEstablecimiento.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.tipoEstablecimientoEdited = null;
			})
			.addCase(deleteTipoEstablecimiento.pending, state => {
				state.messagePopup =
					'Eliminando tipo establecimiento, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.tipoEstablecimientoDeleted = false;
			})
			.addCase(deleteTipoEstablecimiento.fulfilled, (state, action) => {
				state.messagePopup = 'Tipo establecimiento eliminado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.tipoEstablecimientoDeleted = true;
			})
			.addCase(deleteTipoEstablecimiento.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.tipoEstablecimientoDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = tipoEstablecimientoSlice.actions;

export default tipoEstablecimientoSlice.reducer;

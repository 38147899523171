import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TipoMuestraService from 'services/TipoMuestraService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	tipoMuestraEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	tipoMuestraDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createTipoMuestra = createAsyncThunk(
	'tipoMuestra/createTipoMuestra',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoMuestraService.createTipoMuestra,
			rejectWithValue
		);
	}
);

export const editTipoMuestra = createAsyncThunk(
	'tipoMuestra/editTipoMuestra',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoMuestraService.editTipoMuestra,
			rejectWithValue
		);
	}
);

export const deleteTipoMuestra = createAsyncThunk(
	'tipoMuestra/deleteTipoMuestra',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoMuestraService.deleteTipoMuestra,
			rejectWithValue
		);
	}
);

export const tipoMuestraSlice = createSlice({
	name: 'tipoMuestra',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.tipoMuestraEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.tipoMuestraDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createTipoMuestra.pending, state => {
				state.messagePopup = 'Creando tipo de muestra, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createTipoMuestra.fulfilled, state => {
				state.loading = false;
				state.redirect = '/app/configuracion/maestros/tipo-muestra/lista';
				state.messagePopup = 'Se ha registrado un nuevo tipo de muestra.';
				state.messageType = 'success';
			})
			.addCase(createTipoMuestra.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editTipoMuestra.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.tipoMuestraEdited = null;
			})
			.addCase(editTipoMuestra.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Tipo muestra modificado';
				state.messageType = 'success';
				state.tipoMuestraEdited = action.payload;
			})
			.addCase(editTipoMuestra.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.tipoMuestraEdited = null;
			})
			.addCase(deleteTipoMuestra.pending, state => {
				state.messagePopup = 'Eliminando tipo muestra, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.tipoMuestraDeleted = false;
			})
			.addCase(deleteTipoMuestra.fulfilled, (state, action) => {
				state.messagePopup = 'Tipo muestra eliminado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.tipoMuestraDeleted = true;
			})
			.addCase(deleteTipoMuestra.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.tipoMuestraDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = tipoMuestraSlice.actions;

export default tipoMuestraSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';
import qs from 'qs';

import { API_BASE_URL } from 'configs/AppConfig';
import { setDesktopMode, setMobileMode } from './mobileSlice';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	user: localStorage.getItem('me')
		? JSON.parse(localStorage.getItem('me'))
		: null,
};

export const signIn = createAsyncThunk(
	'auth/login',
	async (data, { rejectWithValue }) => {
		const getAuthorization = await fetch(`${API_BASE_URL}/auth/local`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				identifier: data.username,
				password: data.password,
			}),
		});

		try {
			const responseJSON = await getAuthorization.json();

			if (responseJSON['jwt'] !== undefined) {
				const token = responseJSON['jwt'];
				let query = qs.stringify(
					{
						populate: ['role', 'avatar'],
					},
					{ encodeValuesOnly: true }
				);
				localStorage.setItem(AUTH_TOKEN, token);
				const getUser = await fetch(`${API_BASE_URL}/users/me?${query}`, {
					method: 'GET',
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${token}`,
					},
				});
				const responseUserJSON = await getUser.json();
				if (responseUserJSON?.id !== undefined) {
					const me = responseUserJSON;
					localStorage.setItem('me', JSON.stringify(responseUserJSON));
					return { token, me };
				} else {
					return rejectWithValue(responseUserJSON['detail']);
				}
			} else {
				return rejectWithValue(
					responseJSON.error.message === 'Invalid identifier or password'
						? 'Usuario o contraseña incorrectos'
						: responseJSON.error.message
				);
			}
		} catch (err) {
			console.log('err try: ', err);
		}
	}
);

export const signUp = createAsyncThunk(
	'auth/register',
	async (data, { rejectWithValue }) => {
		const { email, password } = data;
		try {
			const response = await AuthService.register({ email, password });
			const token = response.data.token;
			localStorage.setItem(AUTH_TOKEN, token);
			return token;
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error');
		}
	}
);

export const signOut = createAsyncThunk('auth/logout', async () => {
	const response = await FirebaseService.signOutRequest();
	localStorage.removeItem(AUTH_TOKEN);
	localStorage.removeItem('me');
	setDesktopMode();
	return response.data;
});

export const signInWithGoogle = createAsyncThunk(
	'auth/signInWithGoogle',
	async (_, { rejectWithValue }) => {
		try {
			const response = await AuthService.loginInOAuth();
			const token = response.data.token;
			localStorage.setItem(AUTH_TOKEN, token);
			return token;
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error');
		}
	}
);

export const signInWithFacebook = createAsyncThunk(
	'auth/signInWithFacebook',
	async (_, { rejectWithValue }) => {
		try {
			const response = await AuthService.loginInOAuth();
			const token = response.data.token;
			localStorage.setItem(AUTH_TOKEN, token);
			return token;
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error');
		}
	}
);

export const forgotPassword = createAsyncThunk(
	'auth/forgotPassword',
	async (email, { rejectWithValue }) => {
		try {
			const response = await AuthService.forgotPassword(email);
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error');
		}
	}
);

export const resetPassword = createAsyncThunk(
	'auth/resetPassword',
	async (data, { rejectWithValue }) => {
		try {
			const response = await AuthService.resetPassword(data);
			return response.data;
		} catch (err) {
			return rejectWithValue(err.response?.data?.message || 'Error');
		}
	}
);

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false;
			state.redirect = '/';
			state.token = action.payload;
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload;
			state.showMessage = true;
			state.loading = false;
		},
		hideAuthMessage: state => {
			state.message = '';
			state.showMessage = false;
		},
		signOutSuccess: state => {
			state.loading = false;
			state.token = null;
			state.redirect = '/';
		},
		showLoading: state => {
			state.loading = true;
		},
		signInSuccess: (state, action) => {
			state.loading = false;
			state.token = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(signIn.pending, state => {
				state.loading = true;
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/';
				state.token = action.payload.token;
				state.user = action.payload.me;
			})
			.addCase(signIn.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(signOut.fulfilled, state => {
				state.loading = false;
				state.token = null;
				state.redirect = '/';
			})
			.addCase(signOut.rejected, state => {
				state.loading = false;
				state.token = null;
				state.redirect = '/';
			})
			.addCase(signUp.pending, state => {
				state.loading = true;
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/';
				state.token = action.payload;
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(signInWithGoogle.pending, state => {
				state.loading = true;
			})
			.addCase(signInWithGoogle.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/';
				state.token = action.payload;
			})
			.addCase(signInWithGoogle.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			})
			.addCase(signInWithFacebook.pending, state => {
				state.loading = true;
			})
			.addCase(signInWithFacebook.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/';
				state.token = action.payload;
			})
			.addCase(signInWithFacebook.rejected, (state, action) => {
				state.message = action.payload;
				state.showMessage = true;
				state.loading = false;
			});
	},
});

export const {
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;

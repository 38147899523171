import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import qs from 'qs';

const SubprogramaService = {};

SubprogramaService.fetchSubprogramas = query => {
	const fetchQuery = qs.stringify(query, { encodeValuesOnly: true });
	return fetch(
		`${API_BASE_URL}/subprogramas${query ? '?' + fetchQuery : ''}`,
		{
			method: 'GET',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			},
		}
	);
};

SubprogramaService.fetchSubprograma = (id, query) => {
	const fetchQuery = qs.stringify(query, { encodeValuesOnly: true });
	return fetch(
		`${API_BASE_URL}/subprogramas/${id}${query ? '?' + fetchQuery : ''}`,
		{
			method: 'GET',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			},
		}
	);
};

SubprogramaService.createSubprograma = dataThunk => {
	const data = dataThunk;

	return fetch(`${API_BASE_URL}/subprogramas`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({
			data,
		}),
	});
};

SubprogramaService.editSubprograma = dataThunk => {
	const { id, data } = dataThunk;

	return fetch(`${API_BASE_URL}/subprogramas/${id}`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({ data }),
	});
};

SubprogramaService.deleteSubprograma = dataThunk => {
	const id = dataThunk;

	return fetch(`${API_BASE_URL}/subprogramas/${id}`, {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

SubprogramaService.resetearCorrelativo = dataThunk => {
	const id = dataThunk;

	return fetch(`${API_BASE_URL}/subprogramas/resetear-correlativo/${id}`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

export default SubprogramaService;

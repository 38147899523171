import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MetodoService from 'services/MetodoService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	metodoEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	metodoDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createMetodo = createAsyncThunk(
	'metodo/createMetodo',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			MetodoService.createMetodo,
			rejectWithValue
		);
	}
);

export const editMetodo = createAsyncThunk(
	'metodo/editMetodo',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			MetodoService.editMetodo,
			rejectWithValue
		);
	}
);

export const deleteMetodo = createAsyncThunk(
	'metodo/deleteMetodo',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			MetodoService.deleteMetodo,
			rejectWithValue
		);
	}
);

export const metodoSlice = createSlice({
	name: 'metodo',
	initialState,
	reducers: {
		cleanRedirect: (state, action) => {
			state.redirect = '';
		},
		disableMessagePopup: (state, action) => {
			state.messagePopup = '';
		},
		rowEdited: (state, action) => {
			state.metodoEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: (state, action) => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: (state, action) => {
			state.metodoDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createMetodo.pending, state => {
				state.messagePopup = 'Creando metodo, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createMetodo.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/app/configuracion/maestros/metodo/lista';
				state.messagePopup = 'Se ha registrado un nuevo metodo.';
				state.messageType = 'success';
			})
			.addCase(createMetodo.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editMetodo.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.metodoEdited = null;
			})
			.addCase(editMetodo.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Metodo modificado';
				state.messageType = 'success';
				state.metodoEdited = action.payload;
			})
			.addCase(editMetodo.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.metodoEdited = null;
			})
			.addCase(deleteMetodo.pending, state => {
				state.messagePopup = 'Eliminando metodo, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.metodoDeleted = false;
			})
			.addCase(deleteMetodo.fulfilled, (state, action) => {
				state.messagePopup = 'Metodo eliminado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.metodoDeleted = true;
			})
			.addCase(deleteMetodo.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.metodoDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = metodoSlice.actions;

export default metodoSlice.reducer;

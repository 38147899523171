import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import InspectorSernapescaService from 'services/InspectorSernapescaService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	inspectorSernapescaEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	inspectorSernapescaDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createInspectorSernapesca = createAsyncThunk(
	'inspectorSernapesca/createInspectorSernapesca',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			InspectorSernapescaService.createInspectorSernapesca,
			rejectWithValue
		);
	}
);

export const editInspectorSernapesca = createAsyncThunk(
	'inspectorSernapesca/editInspectorSernapesca',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			InspectorSernapescaService.editInspectorSernapesca,
			rejectWithValue
		);
	}
);

export const deleteInspectorSernapesca = createAsyncThunk(
	'inspectorSernapesca/deleteInspectorSernapesca',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			InspectorSernapescaService.deleteInspectorSernapesca,
			rejectWithValue
		);
	}
);

export const inspectorSernapescaSlice = createSlice({
	name: 'inspectorSernapesca',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.inspectorSernapescaEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.inspectorSernapescaDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createInspectorSernapesca.pending, state => {
				state.messagePopup =
					'Creando inspector sernapesca, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createInspectorSernapesca.fulfilled, state => {
				state.loading = false;
				state.redirect =
					'/app/configuracion/maestros/inspector-sernapesca/lista';
				state.messagePopup =
					'Se ha registrado un nuevo inspector sernapesca.';
				state.messageType = 'success';
			})
			.addCase(createInspectorSernapesca.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editInspectorSernapesca.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.inspectorSernapescaEdited = null;
			})
			.addCase(editInspectorSernapesca.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Inspector sernapesca modificado';
				state.messageType = 'success';
				state.inspectorSernapescaEdited = action.payload;
			})
			.addCase(editInspectorSernapesca.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.inspectorSernapescaEdited = null;
			})
			.addCase(deleteInspectorSernapesca.pending, state => {
				state.messagePopup =
					'Eliminando inspector sernapesca, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.inspectorSernapescaDeleted = false;
			})
			.addCase(deleteInspectorSernapesca.fulfilled, (state, action) => {
				state.messagePopup = 'Inspector sernapesca eliminado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.inspectorSernapescaDeleted = true;
			})
			.addCase(deleteInspectorSernapesca.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.inspectorSernapescaDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = inspectorSernapescaSlice.actions;

export default inspectorSernapescaSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TipoAnalisisService from 'services/TipoAnalisisService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	tipoAnalisisEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	rowToDelete: null,
	tipoAnalisisDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createTipoAnalisis = createAsyncThunk(
	'tipoanalisis/createTipoAnalisis',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoAnalisisService.createTipoAnalisis,
			rejectWithValue
		);
	}
);

export const editTipoAnalisis = createAsyncThunk(
	'tipoanalisis/editTipoAnalisis',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoAnalisisService.editTipoAnalisis,
			rejectWithValue
		);
	}
);

export const deleteTipoAnalisis = createAsyncThunk(
	'tipoanalisis/deleteTipoAnalisis',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			TipoAnalisisService.deleteTipoAnalisis,
			rejectWithValue
		);
	}
);

export const tipoAnalisisSlice = createSlice({
	name: 'tipoanalisis',
	initialState,
	reducers: {
		cleanRedirect: state => {
			state.redirect = '';
		},
		disableMessagePopup: state => {
			state.messagePopup = '';
		},
		rowEdited: state => {
			state.tipoAnalisisEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: state => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: state => {
			state.tipoAnalisisDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createTipoAnalisis.pending, state => {
				state.messagePopup = 'Creando tipo de análisis, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createTipoAnalisis.fulfilled, state => {
				state.loading = false;
				state.redirect = '/app/configuracion/maestros/tipo-analisis/lista';
				state.messagePopup = 'Se ha registrado el nuevo tipo de análisis.';
				state.messageType = 'success';
			})
			.addCase(createTipoAnalisis.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editTipoAnalisis.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.tipoAnalisisEdited = null;
			})
			.addCase(editTipoAnalisis.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Tipo análisis modificado';
				state.messageType = 'success';
				state.tipoAnalisisEdited = action.payload;
			})
			.addCase(editTipoAnalisis.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.tipoAnalisisEdited = null;
			})
			.addCase(deleteTipoAnalisis.pending, state => {
				state.messagePopup =
					'Eliminando tipo análisis, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.tipoAnalisisDeleted = false;
			})
			.addCase(deleteTipoAnalisis.fulfilled, (state, action) => {
				state.messagePopup = 'Tipo análisis eliminado';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.tipoAnalisisDeleted = true;
			})
			.addCase(deleteTipoAnalisis.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.tipoAnalisisDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = tipoAnalisisSlice.actions;

export default tipoAnalisisSlice.reducer;

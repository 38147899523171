import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	AUTHENTICATED_ENTRIES_BY_ROLE,
	DEFAULT_AUTHENTICATED_ENTRY,
} from 'configs/AppConfig';

const PublicRoute = () => {
	const { token, user } = useSelector(state => state.auth);

	return token ? (
		<Navigate
			to={
				AUTHENTICATED_ENTRIES_BY_ROLE[user.role?.type] ||
				DEFAULT_AUTHENTICATED_ENTRY
			}
		/>
	) : (
		<Outlet />
	);
};

export default PublicRoute;

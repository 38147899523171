import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MatrizService from 'services/MatrizService';
import { responseSliceHandler } from 'utils/responseSliceHandler';

export const initialState = {
	loading: false,
	loadingSubmitRegister: false,
	matrizEdited: null,
	modeEditor: true,
	allowSaveData: true,
	modalDeleteOpen: false,
	modalConfirmLoading: false,
	rowToDelete: null,
	matrizDeleted: false,
	onSubmitLoading: false,
	messagePopup: '',
	messageType: '',
	redirect: '',
};

export const createMatriz = createAsyncThunk(
	'matriz/createMatriz',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			MatrizService.createMatriz,
			rejectWithValue
		);
	}
);

export const editMatriz = createAsyncThunk(
	'matriz/editMatriz',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			MatrizService.editMatriz,
			rejectWithValue
		);
	}
);

export const deleteMatriz = createAsyncThunk(
	'matriz/deleteMatriz',
	async (data, { rejectWithValue }) => {
		return await responseSliceHandler(
			data,
			MatrizService.deleteMatriz,
			rejectWithValue
		);
	}
);

export const matrizSlice = createSlice({
	name: 'matriz',
	initialState,
	reducers: {
		cleanRedirect: (state, action) => {
			state.redirect = '';
		},
		disableMessagePopup: (state, action) => {
			state.messagePopup = '';
		},
		rowEdited: (state, action) => {
			state.matrizEdited = null;
		},
		showModalDelete: (state, action) => {
			state.rowToDelete = action.payload;
			state.modalDeleteOpen = true;
		},
		hideModalDelete: (state, action) => {
			if (!state.onSubmitLoading) {
				state.modalDeleteOpen = false;
			}
		},
		rowDeleted: (state, action) => {
			state.matrizDeleted = false;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(createMatriz.pending, state => {
				state.messagePopup = 'Creando matriz, por favor espere';
				state.messageType = 'info';
				state.loading = true;
			})
			.addCase(createMatriz.fulfilled, (state, action) => {
				state.loading = false;
				state.redirect = '/app/configuracion/maestros/matriz/lista';
				state.messagePopup = 'Se ha registrado una nueva matriz.';
				state.messageType = 'success';
			})
			.addCase(createMatriz.rejected, (state, action) => {
				state.loading = false;
				state.messagePopup = action.payload;
				state.messageType = 'error';
			})
			.addCase(editMatriz.pending, state => {
				state.modeEditor = false;
				state.allowSaveData = false;
				state.messagePopup =
					'Estamos guardando los cambios, por favor espere...';
				state.messageType = 'info';
				state.matrizEdited = null;
			})
			.addCase(editMatriz.fulfilled, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = 'Matriz modificada';
				state.messageType = 'success';
				state.matrizEdited = action.payload;
			})
			.addCase(editMatriz.rejected, (state, action) => {
				state.modeEditor = true;
				state.allowSaveData = true;
				state.messagePopup = action.payload;
				state.messageType = 'error';
				state.matrizEdited = null;
			})
			.addCase(deleteMatriz.pending, state => {
				state.messagePopup = 'Eliminando matriz, por favor espere...';
				state.messageType = 'info';
				state.onSubmitLoading = true;
				state.matrizDeleted = false;
			})
			.addCase(deleteMatriz.fulfilled, (state, action) => {
				state.messagePopup = 'Matriz eliminada';
				state.messageType = 'success';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.matrizDeleted = true;
			})
			.addCase(deleteMatriz.rejected, (state, action) => {
				state.message = action.payload;
				state.messageType = 'error';
				state.onSubmitLoading = false;
				state.modalDeleteOpen = false;
				state.rowToDelete = null;
				state.matrizDeleted = true;
			});
	},
});

export const {
	cleanRedirect,
	disableMessagePopup,
	rowEdited,
	showModalDelete,
	hideModalDelete,
	rowDeleted,
} = matrizSlice.actions;

export default matrizSlice.reducer;
